.selection-cell-header{
    background-color: rgb(241, 241, 241) !important;
}

.borderstyle{
    background: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 5px !important;
    color: #CCCCCC !important;
}

  .patient-card .custom-padding-input{
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
.form-text-color{
    color: #CCCCCC !important;
}
.icon-color{
    color: #B3B3B3 !important;
}

 .location-div{
    max-width: 110px;
    min-width: 90px;
}
.patient-card .react-bootstrap-table-pagination {
    display: flex;
    align-items: center;
    margin: 0px 10px !important
}
.patient-card .react-bootstrap-table-pagination-list{
    padding-top: 10px !important;
}

.patient-details-card .react-bootstrap-table-pagination {
    display: flex !important;
    align-items: center !important;
    padding-top: 13px !important;
}
.patient-details-card .react-bootstrap-table-pagination-list{
    padding-top: 10px !important;
}

@media screen and (min-width: 421px) and (max-width: 566px) {
    .patient-details-card .custom-row{
        flex-direction: column !important;
    }
  
}
@media screen and (min-width: 980px) and (max-width: 1052px) {
    .patient-details-card .custom-row{
        flex-direction: column !important;
    }
  
}
@media screen and (min-width: 768px) and (max-width: 806px) {
    .patient-details-card .custom-row{
        flex-direction: column !important;
    }
  
}
@media screen and (min-width: 0px) and (max-width: 420px) {
    .patient-details-card .custom-row{
        flex-direction: column !important;
    }
    .patient-appointment-notes-details .custom-row{
        flex-direction: column !important;
    }
    .patient-appointment-notes-details .custom-padding{

        padding-left: 0px !important;
    }

    .patient-details-card .custom-div-row{
        flex-direction: column !important;
    }
  
}
 .header-border{
    border-left: 6px solid #000071 ;
 }

.patient-details .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #F26522 !important;
    border-color: transparent !important;
    color: white !important;
} 
.patient-details .nav-pills .nav-link{
    border: 2px solid #CCCCCC !important;
    color: #CCCCCC !important;
} 

.patient-profile .nav-pills .nav-link.active .Arrow-ForwardIcon, .nav-pills .show>.nav-link .Arrow-ForwardIcon {
    display: block !important;
} 

.patient-profile  .nav-pills .nav-link .Arrow-ForwardIcon{
    display: none !important;
}


.patient-details-card .table-header-border{
    border: 1px solid #CCCCCC !important;
    
    border-radius: 5px 5px 0px 0px !important;
}
.patient-clinical-note .table-header-border{
    border: 1px solid #CCCCCC !important;
    background-color: white;
    border-radius: 5px;
}
.patient-clinical-note .table-body-border{
    border: 1px solid #CCCCCC !important;
    border-top: 0px !important;
    border-radius: 0px 0px 5px 5px;
    background-color: #FFFFFF;
}
.patient-details-card .react-bootstrap-table{
    border: 1px solid #CCCCCC !important;
    border-top: 0px !important;
    border-radius: 0px 0px 5px 5px !important;
    background-color: #FFFFFF !important;
}
.appointment-icon-size{
    height: 19px;
}
.patient-appointment-notes-details .appointment-icon-size{
    height: 25px;
}
.patient-appointment-notes-details .accordion-collapse{
    background-color: #FFFFFF;
    color: #1A1A1A;
    font-weight: 400;
    font-size: 16px;
  }
  .patient-appointment-notes-details .accordion-header button{
    background-color: #FFFFFF;
    color: #1A1A1A;
    font-weight: 400;
  font-size: 16px;
  }
  /* .patient-appointment-notes-details .accordion-button:focus{
    box-shadow: none;
  } */


  .mainDiv{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 10px;
   
  }

  .search-width{
    width: 50% ;
  }

  @media screen and (max-width: 1800px) {
    .mainDiv{
        display: grid !important;
        grid-template-columns: auto auto auto auto;
        
      }
  }
  
  @media screen and (max-width: 1600px) {
    .mainDiv{
        display: grid !important;
        grid-template-columns: auto auto auto;
    
      }
  }
  @media screen and (max-width: 1290px) {
    .mainDiv{
        display: grid !important;
        grid-template-columns: auto auto;
    
      }
  }
  @media screen and (max-width: 991) {
    .search-width{
        width: 100% !important;
      }
  }
  @media screen and (max-width: 660px) {
    .mainDiv{
        display: grid !important;
        grid-template-columns: auto;
    
      }
      .search-width{
        width: 100% !important;
      }
  }
  .custom-toggle::after {
    display: none !important;
  }

  .hover-transparent:hover {
    background-color: transparent !important;
  }
  .patient-profile .accordion-button:not(.collapsed){
    color: black;
     background-color: transparent !important;
  }
  .patient-profile .Phone-Number-FieldWidth{
    width: 90%;
    height: 45%;
  }
  .Phone-Number-FieldWidth{
    width: 90%;
    height: 45%;
  }