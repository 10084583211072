@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

body {
  background-color: #F6F8FF !important;
  font-family: "Inter", sans-serif !important;
  color: #1A1A1A !important;
}
::selection {
  background-color: rgb(0, 0, 113, 63%);
  color: #fff;
}
.form-control:focus {
  border-color: #000071 !important;
  box-shadow: 0 0 0 0.25rem rgb(0, 0, 113, 63%) !important;
}

.signin-component,
.forget-component {
  background-color: white;
}

.register-patient {
  color: #1A1A1A !important;
  font-size: 18px !important;
}

.generic-heading-level-2 {
  font-weight: 600;
}

.dt-round-img {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}

.loader-custom-h {
  height: 100vh;
}

.user-details-card .loader-custom-h,
.custom-loading-h .loader-custom-h,
.patient-pharmacy .loader-custom-h {
  height: auto;
}

.text-cursor-pointer {
  cursor: pointer;
}

.btn-header {
  background: linear-gradient(0deg, #39b6fe, #39b6fe), #ffffff;
  border-radius: 4px;
  border: none;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.tele-consultation-tabs .nav-tabs .nav-link.active {
  border-top: 5px solid #000071;
  color: #000000;
  border-right: none;
  border-left: none;
  border-radius: 0px;
}

.tele-consultation-tabs .nav-tabs .nav-link:hover {
  background-color: transparent;
  border-color: transparent;
}

.tele-consultation-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.tele-consultation-tabs .nav-link {
  color: #B3B3B3;
}

.tele-consultation-accordion .accordion-collapse {
  background-color: #FBFBFB;
  color: #1A1A1A;
  font-weight: 400;
  font-size: 16px;
}

.tele-consultation-accordion .accordion-header button {
  background-color: #FBFBFB;
  color: #1A1A1A;
  font-weight: 400;
  font-size: 16px;
}

.tele-consultation-accordion .accordion-button:focus {
  box-shadow: none;
}

.tele-consultation-accordion .accordion-item {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border: 1px solid #CCCCCC !important;
}

.appointment-notes-accordion .accordion-header .accordion-button {
  padding: 25px 48px;
  font-size: 22px;
  font-weight: 500;
  color: #1A1A1A;
  background-color: #ffffff;
}

.appointment-notes-accordion .accordion-item {
  border: 0px;
}

.appointment-notes-accordion .form-check-label {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 10px;
  margin: 3px 0px;
}

.appointment-notes-accordion .form-check-input {
  width: 20px;
  height: 20px;
}

.appointment-notes-accordion .form-check-input:checked,
.edit-prescription-modal .form-check-input:checked {
  background-color: #000071;
  border-color: #000071;
}

.custom-field-picker {
  border: 1px solid #D9D9D9 !important;
  border-radius: 5px !important;
  padding: 11px !important;
}

.dr-date-w {
  width: 160px;
}

.patient-profile-dob-w {
  width: 160px;
}

.patient-profile-cd-w {
  width: 164px;
}

.doctors-apt-modal-table thead {
  display: none;
}

.doctors-apt-modal-table .react-bootstrap-table table tbody tr:nth-of-type(even) {
  background: none !important;
}

.doctors-apt-modal-table .react-bootstrap-table table tbody tr {
  cursor: pointer;
}


.doctors-apt-modal-table .react-bootstrap-table {
  max-height: 13rem;
  overflow: scroll;
}

.invoice-cd-w {
  width: 148px;
}

.custom-field-picker .react-time-picker__wrapper {
  border: none;
  color: #999999;
}

.custom-date-icon {
  position: relative;
  right: 24px;
  top: 15px;
  color: #999999;
}

.med-certificate .custom-date-icon {
  position: absolute;
  right: 15px;
  top: 17px ;
  color: #999999;
}
.med-certificate .form-control:disabled, .med-certificate .form-select:disabled{
  background: transparent;
}

.prescription-date-picker {
  position: relative;
  right: 30px;
  top: 16px;
  color: #999999;
}

.custom-padding-date {
  padding: 12px 20px;
}

.patient-date-wrapper .custom-date-icon {
  position: relative;
  right: 23px;
  top: 16px !important;
  color: #999999;
}

.dr-date-wrapper .react-datepicker-wrapper,
.patient-date-wrapper .react-datepicker-wrapper,
.admin-date-wrapper .react-datepicker-wrapper,
.superadmin-date-wrapper .react-datepicker-wrapper {
  width: auto;
  margin-right: -10px;
}

.patient-profile-wrapper .react-datepicker-wrapper {
  width: auto;
  margin-right: -15px;
}

.appointment-notes-accordion .react-time-picker__clock {
  display: none;
}

.header-icon {
  width: 50%;
}

.tele-consultation-tabs .nav-tabs {
  border: none
}

.current-pharmacy-detials {
  background: #FBFBFB !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 7px !important;
  box-sizing: border-box !important;
}

.user-menu .badge {
  background-color: #f26522 !important;
}

.notification-icon {
  position: absolute;
  right: 16.1%;
  top: 4.9px;
  font-size: 6px !important;
}

.inner-page-wrapper{
  margin-top: 78px;
  padding: 36px 36px 0px 70px !important;
}

.label-primary {
  font-weight: 500 !important;
  font-size: 18px !important;
}

input::placeholder,
.card-content>span p:nth-child(1) {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #999999 !important;
  padding-right: 10px !important;
}

.card-content>span p:nth-child(2) {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #1A1A1A !important;
}

.card-content>span p,
.card-content>hr {
  margin: 0px;
}

.card-content span {
  padding: 28px 0px;
}

.otpfield {
  padding: 12px 6px !important;
}

.otp-field {
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
}

.text-gray {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
}

.text-black {
  font-weight: 400;
  font-size: 20px;
}

.head-user-text {
  font-weight: 500;
  font-size: 20px;
  color: #1A1A1A;
}

.head-role-text {
  font-weight: 400;
  font-size: 14px;
  color: #1A1A1A;
}

.modal-footer {
  border-top: none !important;
}

.header-user-text {
  font-weight: 500;
  font-size: 16px;
  color: #1A1A1A;
}

.bell-icon {
  color: #B3B3B3;
  cursor: pointer;
}

.search-field-spacing {
  padding: 11px 16px !important;
}

.searchbar-icon {
  position: relative;
  right: 40px;
  top: 14px;
  color: #B3B3B3
}

.chatSection .searchbar-icon {
  right: 33px !important;
  top: 9px !important;
}

.table-bold-text {
  font-weight: 500;
  font-size: 16px;
}

.table-normal-text {
  font-weight: 400;
  font-size: 16px;
}

.table-action {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #3F8BFC !important;
  text-decoration: none;
}

.card {
  border: none !important;
}

.custom-scrollbar {
  height: 100%;
  max-height: calc(100vh - 382px);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-prescription-scroll {
  height: 100%;
  max-height: calc(100vh - 242px);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-table-spacing table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.notify-dot::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #F26522;
  border: 1px solid #FFFFFF;
}

.action-data {
  color: #ffffff;
  background-color: #000071;
  border-radius: 5px;
  text-align: center;
  width: 30%;
  cursor: pointer;
}

.doctors-apt-table table tr td:nth-last-child(1) {
  display: flex;
  justify-content: center;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.superadmin-custom-scrollbar {
  height: 100%;
  max-height: calc(100vh - 732px);
  overflow-y: auto;
  overflow-x: hidden;
}

/* Full Calendar */
.fc .fc-button {
  border: 1px solid #E9E9E9 !important;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04) !important;
  color: #CCCCCC;
  border-radius: 5px !important;
  padding-left: 20px !important;
  font-weight: 600 !important;
  padding-right: 20px !important;
  text-transform: capitalize;
}

.chat-box-shadow-lt {
  border-radius: 12px 0px 0px 12px;
  box-sizing: border-box;
  box-shadow: 0 0 8px #C3C3C3;
  -webkit-clip-path: inset(-5px 0px -5px -5px);
  clip-path: inset(-5px 0px -5px -5px);
}

.chat-box-shadow-rt {
  border-radius: 0px 12px 12px 0px;
  box-sizing: border-box;
  box-shadow: 0 0 8px #C3C3C3;
  -webkit-clip-path: inset(-5px -5px -5px 0px);
  clip-path: inset(-5px -5px -5px 0px);
}

.fc .fc-button:hover {
  color: #CCCCCC;
}

.fc-button-active {
  color: #1A1A1A !important;
  font-weight: 500 !important;
}

.fc-toolbar-title {
  font-size: 22px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  padding: 0px 20px !important;
}

.fc-media-screen .fc-header-toolbar>div:nth-child(2) {
  padding-right: 12%;
}

.fc-header-toolbar.fc-toolbar>div:nth-child(2)>div {
  display: flex !important;
  align-items: center !important;
}

.apt-badge .bg-primary {
  background-color: #F2F2F2 !important;
  color: #1A1A1A !important;
}

.apt-badge .bg-danger {
  background-color: #FFEFD7 !important;
  color: #F26522 !important;
}

.apt-badge .bg-info {
  background-color: #E2F3FF !important;
  color: #2269F2 !important;
}

.apt-badge .bg-light {
  background-color: #ECFFCC !important;
  color: #44BC19 !important;
}

.apt-badge .bg-warning {
  background-color: #FFE5E5 !important;
  color: #FD2121 !important;
}

.fc-header-dropdown .dropdown button {
  position: absolute !important;
  right: 1px !important;
  background: white !important;
  color: #CCCCCC !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}

.fc-header-dropdown .dropdown-menu,
.apt-filter-dropdown .dropdown-menu {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0px;
  padding: 10px;
}

.fc-header-dropdown .form-check-input:checked,
.apt-filter-dropdown .form-check-input:checked {
  background-color: #000071;
  border-color: #000071;
}

.generic-h100 .card {
  height: 100%;
}

.fc-scroller table thead tr th {
  background-color: #F1F4FF;
  padding: 8px 0px;
}

.fc-scroller table thead tr th .fc-scrollgrid-sync-inner a {
  font-size: 15.5521px;
  color: #232A3E;
  font-weight: 600;
  text-decoration: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: #232A3E;
  font-weight: 500;
  font-size: 19.4401px;
}

.fc-h-event {
  border-radius: 6px !important;
  margin: 0px 14px !important;
  padding: 14px 12px !important;
}

.fc-daygrid-day-events {
  margin-bottom: 15px;
}

.fc-view-harness {
  z-index: 0;
}

.fc-button-group {
  z-index: 0;
}

.fc-daygrid-day-frame {
  background-color: #ffffff !important;
}

.main-row .card h3 {
  display: flex !important;

}

/* .main-row img {
  width: 44px;
} */

.highcharts-credits {
  display: none !important;
}

.bnLuHU {
  background-color: white !important;
}

.custom-date {
  padding: 9px 8px !important;
  border: 1px solid #CCCCCC !important;
  color: #999999 !important;
}

.table>:not(caption)>*>* {
  border-bottom: 0px;
}

.react-bootstrap-table table tbody tr:nth-of-type(odd) {
  background-color: white;
}

.react-bootstrap-table table tbody tr:nth-of-type(even) {
  background-color: #FAFBFF;
}

.react-bootstrap-table table {
  margin-bottom: 0px;
}

.doctor-datatable .react-bootstrap-table-pagination,
.patient-datatable .react-bootstrap-table-pagination {
  display: flex !important;
  align-items: center !important;
  margin: 0px 10px !important;
}

.react-bootstrap-table-pagination-list ul {
  float: right !important;
}

.react-bootstrap-table-pagination-list ul li {
  padding-left: 10px;
  border-radius: 50px !important;
}

.react-bootstrap-table table thead tr th {
  padding: 15px 15px;
}

.center-text {
  text-align: center;
}

.react-bootstrap-table table tbody tr td {
  padding: 10px 15px !important;
  vertical-align: middle;
}

.dr-list-spacing .react-bootstrap-table table tbody tr td {
  padding: 23px 15px !important;
}

.pagination>li>a {
  background-color: #F5F5F5;
}

.pagination>.active>a, .patient__prescription-pagination .page-item.active .page-link {
  background-color: #000071 !important;
  border-color: #000071 !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.custom-outline-btn {
  padding: 11px 20px !important;
  background-color: #ffffff !important;
  color: #000071 !important;
  border: 1px solid #000071 !important;
}

.custom-outline-filter {
  padding: 6px 8px !important;
  background-color: #ffffff !important;
  border: 1px solid #CCCCCC !important;
}

.apt-filter-dropdown .dropdown-toggle::after {
  display: none;
}

.prescription-outline-btn {
  background-color: #ffffff !important;
  color: #000071 !important;
  border: 1px solid #000071 !important;
}

.custom-dropdown button {
  background: #FFFFFF !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 5px !important;
  color: #CCCCCC !important;
  padding: 11px 18px !important;
}

.calendar-card {
  background: #FAFBFF;
  border: 1px solid #D9D9D9;
  border-radius: 7px;
}

select {
  font-weight: 400 !important;
  font-size: 12.4687px !important;
}

.breadcrum_Project a {
  text-decoration: none !important;
  color: #1a1a1a !important;
  font-size: 24px !important;
  text-transform: capitalize;
}

.simple-header .breadcrum_Project {
  font-size: 37px;
}

.breadcrumb-item::before {
  display: none;
}

.breadcrum_addProject {
  color: #818181 !important;
}

.breadcrumb-item.active {
  color: #1A1A1A !important;
}

.header-breadcrumb ol {
  margin-bottom: 0px;
  font-weight: bold !important;
  font-size: 22px !important;

}

.table-custom-data {
  font-size: 18px;

}

.btn-closeAndBack {
  border: 0 !important;
  background-color: transparent !important;
  font-weight: 600;
  font-size: 15.4332px;
  color: #000000;
}

.custom-modal .btn-primary {
  background: #000071 !important;
  border: none !important;
}

.btn-primary {
  background: #000071;
  border-radius: 5px;
  padding: 12px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  border: none;
}

.signin-component .btn-primary,
.forget-component .btn-primary {
  background: #000071 !important;
  border: none !important;
}

.btn-draft {
  border: var(--bs-border-width) var(--bs-border-style) #0971ad !important;
  background-color: transparent !important;
  font-weight: 600;
  font-size: 14px;
  color: #0971ad;
  padding: 4px 8px !important;
  border-radius: 4px !important;
}

/* .signin-component {
  height: 100vh;
} */

.signin-component .bg-img {
  background-image: url("../images/doctor-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position-x: right;
}

.forget-component .bg-img2 {
  background-image: url("../images/doctor-bg-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position-x: right;
}

.signin-component .signin-page,
.forget-component .signin-page {
  background: rgb(0, 0, 113, 63%); 
  height: 100vh;
}
/* .auth-text-section{
  max-width: 40%;
  position: absolute;
  top: 30%;
  left: 5%;
} */

.signin-component .heading1,
.forget-component .heading1 {
  font-weight: 700;
  font-size: 4.5rem;
  margin-bottom: 2.5rem;
  color: #ffffff;
}

.signin-component .heading2,
.forget-component .heading2 {
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
}

.signin-component .signin-content,
.forget-component .signin-content {
  width: 65%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.signin-heading {
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 10px;
}

.eye-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #999999;
  cursor: pointer;
}

.back-sign-in,
.back-sign-in:hover {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: #1A1A1A;
}

.eJVmkY {
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.error-message-field-generic {
  color: #f22222;
  font-size: 14px;
}

.forget-pass {
  color: #74ACFF !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-decoration: underline !important;
}

.appointment-modal-title {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1A1A1A;
}

/* .img-card {
  background-image: url('./../images/bg-bottom-rounded.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 318px;
  display: flex;
  padding-top: 70px;
  align-items: center;
  flex-direction: column;
} */

.profile-img-test {
  max-width: 40%;
}

.video-appointment {
  background-color: #000000;
  height: 720px;
}

.video-appointment .not-joined-text {
  font-weight: 400;
  color: #FFFFFF;
  font-size: 20px;
  width: 36%;
  line-height: 26px;
}

.exclamation-icon {
  color: #FFB400;
}

.video-appointment .botton-icons,
.appointment-started .botton-icons {
  cursor: pointer;
  padding: 15px 5px;
  color: white;
  background: #000071;
  border-radius: 50%;
}

.appointment-modal .modal-content {
  border-radius: 20px;
  width: 640px;
  margin: auto;
}

.appointment-modal .modal-header {
  padding-top: 26px;
  border-bottom: 0px;
}

.stethoscope-icon {
  color: #000071;
  padding: 16px;
  background: #EDEAFD;
  border-radius: 50%;
}

.start-apt-btn {
  background: #000071 !important;
  padding: 10px 34px !important;
}

.end-apt-btn {
  background: #FD2121 !important;
  padding: 10px 34px !important;
  border: none !important;
}

.referral-main-text {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
}

.color-99 {
  color: #999999
}

/*--------- Edit Dropdown---------- */
.moreIcon {
  color: black;
  border: none;
  background: none;
}

.moreIcon .dropdown-toggle::after {
  visibility: hidden;
}

.set-dropdown-menu {
  overflow-x: hidden !important;
  font-size: 14px !important;
}

.edit-dropdown .dropdown-menu {
  --bs-dropdown-min-width: 7rem;
}

.edit-dropdown .dropdown-menu {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: none;
}

/* ---------------------- */

.simple-header {
  background-color: #ffffff;
  height: 165px;
}

.policies-header {
  background-color: #ffffff;
  height: 100px;
}

.prescription-btn {
  background: #00007114 !important;
  color: #000071 !important;
  border-radius: 5px !important;
  border: none !important;
}

.prescription-modal-title {
  font-weight: 500;
  font-size: 24px;
}

.prescription-modal .form-check-label {
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}

.edit-btn {
  background-color: #F1F9FF !important;
  color: #2269F2 !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 19px !important;
}

.custom-text-underline {
  color: #000071;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  float: right;
}

.patient-calendar .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.patient-calendar .react-datepicker__day:hover {
  background-color: #F26522 !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.patient-calendar .react-datepicker__month {
  margin: 0px;
}

.patient-calendar .react-datepicker__header,
.patient-calendar .react-datepicker__month-container {
  background: #F9F9F9 !important;
  border: 0px;
  width: 100%;
}

.patient-calendar .react-datepicker__month-container {
  overflow: auto;
}

.patient-calendar .react-datepicker__day-name {
  color: #666666;
}

.patient-calendar .react-datepicker__day-name,
.patient-calendar .react-datepicker__day {
  width: 2.6rem;
  font-size: 1.18rem;
  line-height: 2.5rem;
  margin: 0px 5px;
}

.patient-calendar .react-datepicker {
  width: 100%;
  border: 0px !important;
}

.patient-calendar .react-datepicker__current-month {
  font-size: 1.5rem;
  margin: 0px;
}

.patient-calendar .react-datepicker__navigation-icon::before {
  border-color: #333333;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: block;
  height: 12px;
  position: absolute;
  top: 15px;
  width: 12px;
}

.patient-calendar .react-datepicker__navigation--previous {
  left: 34px;
  top: 5px;
}

.patient-calendar .react-datepicker__navigation--next {
  right: 34px;
  top: 5px;
}

.dr-apt-overview .highcharts-legend-item text {
  font-size: 15px !important;
}

.apt-inner-card {
  border: 0.8px solid #000071;
  border-radius: 5.6px;
}


@media screen and (max-width: 576px) {
  .inner-page-wrapper{
    margin-top: 78px;
    padding: 20px !important;
  }
}

@media screen and (max-width: 1232px) and (min-width: 1200px) {
  .payment-dashboard-table {
    height: 439px !important;
  }
}

@media screen and (max-width: 1293px) and (min-width: 1200px) {
  .patient-dashboard-table {
    height: 478px !important;
  }
}

.patient-custom-scrollbar {
  max-height: 21rem;
    overflow: scroll;
}

@media only screen and (max-width: 1295px) and (min-width: 1233px) {
  .payment-custom-scrollbar {
    height: 100%;
    max-height: calc(100vh - 486px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1232px) and (min-width: 1200px) {
  .payment-custom-scrollbar {
    height: 100%;
    max-height: calc(100vh - 388px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.calendar-fix-height {
  height: 20rem;
  overflow: scroll;
}

.patient-custom-scrollbar-admin {
  max-height: 13.5rem;
  overflow: scroll;
}

@media screen and (min-width: 576px) and (max-width: 796px) {
  .display-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 576px) {
  .xs-margin-bottom {
    margin-bottom: 20px;
  }

  .breadcrumb_xs-none {
    display: none;
  }
  .custom-col-rev{
    flex-direction: column-reverse;
  }
  .text-w-full {
    width: 100%
  }
}

.print-data {
  color: #0971ad !important;
}

.print-data .Toastify__progress-bar-theme--light {
  background: #0971ad !important;
}

.print-data .Toastify__toast-icon svg {
  fill: #0971ad !important;
}

.notification-img {
  width: 35px;
  background-color: #f4f6f9;
  height: 35px;
  border-radius: 50px;
  display: flex !important;
  margin-right: 0.5rem !important;
}

.notify-name {
  width: 100% !important;
  text-align: center !important;
  align-self: center !important;
  font-weight: 700;
}

.notification-custom-icons {
  width: 48px;
  height: 44px;
  border-radius: 50%;
}

.accept-btn {
  background: #71d76f !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.reject-btn {
  background: #39b6fe !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

/* .dropdown.show:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: 1;
} */

.sidebar-log-img {
  max-width: 100%;
  height: auto;
  height: 62px;
  width: 170px;
}

body .modal-backdrop.show {
  --bs-backdrop-bg: unset;
}

body .fade.modal-backdrop.show {
  --bs-backdrop-bg: #000;
}

.fc .fc-daygrid-event-harness {
  margin-bottom: 10px;
}

.appointment-calender a.fc-event {
  padding: 0 !important;
}

.appointment-slot-background {
  padding: 15px;
  border-radius: 5px;
}

.subscriber .OTSubscriberContainer {
  width: 100% !important;
  height: 700px !important;
}

.videocall.publisher {
  position: absolute;
  width: 100%;
  top: 68%;
  bottom: 0;
}

.videocall.publisher .publisher-video-box {
  min-height: 12rem !important;
}

.OT_publisher .OT_mute,
.OT_subscriber .OT_mute {
  display: none !important;
}

.OT_publisher .OT_bar,
.OT_subscriber .OT_bar {
  display: none !important;
}

.consultation-main .nav-tabs .nav-item .active {
  border-top-left-radius: 5px;
}

.payment-checkout .checkout-country-col .form-control {
  padding: 0.65rem 0.75rem !important;
}

.familyDoc-zipcode.form-select {
  border-radius: 0.375rem 0.375rem 0px 0px !important;
}

.publisher .publisher-name {
  background: rgba(0, 0, 0, 0.30);
  /* backdrop-filter: blur(2.5716190338134766px); */
  padding: 5px 10px;
  border-radius: 25px;
}

.subscriber .publisher-name {
  background: rgba(0, 0, 0, 0.30);
  /* backdrop-filter: blur(2.5716190338134766px); */
  padding: 5px 10px;
  border-radius: 25px;
}

.publisher .publisher-mute {
  background: #fd2121;
  border-radius: 50%;
}

/*  Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type=number] {
  -moz-appearance: textfield;
}

.chatSection .staff-chat {
  min-height: calc(100vh - 10rem) !important;
}

.chatSection .chat-scroll {
  height: calc(100vh - 20rem) !important;
}

.chatSection .sender-message {
  word-wrap: break-word !important;
  white-space: normal !important;
  max-width: calc(100vh - 8rem);
}

/* .react-bootstrap-table table thead tr th {
  padding: 18px 42px;
} */
.patient-pharmacy .react-bootstrap-table table tbody tr td {
  padding: 18px 42px !important;
}

.chatSection .message-container {
  width: 250px;
  /* Set the width to the desired value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeChat {
  background: #eee;
  border-radius: 5px;
  transition: all ease-in-out .3s;
}

.hoverChat:hover {
  background: #eee !important;
  border-radius: 5px !important;
  transition: all ease-in-out .3s !important;
}

.chatSection .div-fixed-height {
  max-height: calc(100vh - 20rem) !important;
  min-height: calc(100vh - 20rem) !important;
  height: 100%;
  overflow: auto;
}

.Staff-Chat-MainClass .div-fixed-height {
  max-height: calc(100vh - 23rem) !important;
  min-height: calc(100vh - 23rem) !important;
  height: 100%;
  overflow: auto;
}

.Staff-Chat-MainClass .sender-message {
  word-wrap: break-word !important;
  white-space: normal !important;
  max-width: calc(100vh - 8rem);
}

.Staff-Chat-MainClass .message-container {
  width: 250px;
  /* Set the width to the desired value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-apt-height {
  height: 18.4rem;
}

.custom-apt-height-admin {
  height: 206px;
}

.chatSection .staff-inbox {
  max-height: calc(100vh - 23rem) !important;
  overflow: auto;
}

.patient-note .patient-notes-data {
  max-height: 20.5rem;
  overflow: scroll;
}

.referrel-note .referrel-note-data {
  max-height: 20rem;
    overflow: scroll;
}

.user-menu .notification-item .dropdown-menu {
  width: 27.8rem;
  padding: 0;
  min-height: calc(100vh - 42rem) !important;
}

.pdf-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.pdf-table td,
.pdf-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.pdf-table tr:nth-child(even) {
  background-color: #dddddd;
}

.custom-text-ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-link,
.text-link:hover {
  text-decoration: none;
  color: #1A1A1A;
}

* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
}

.stripe label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}


.stripe input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #c4c4c4ff;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripe input::placeholder {
  color: #aab7c4;
}

.stripe input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe .StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe .StripeElement.PaymentRequestButton {
  height: 40px;
}

.modal-stripe .modal-content {
  width: 400px;
}

.custom-full-calendar .fc-popover-body {
  height: 200px;
  overflow: scroll;
}

.uploaded-img-layout {
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: contain;
}

.rotate-img{
  animation: rotate 3s linear infinite; 
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}

.pt-ref-notes .accordion-button {
  font-size: 1.25rem !important;
}

.pt-ref-notes .specialist-letter .accordion-button {
  color: #2269F2 !important;
}

.pt-ref-notes .emergency-letter .accordion-button {
  color: #E31E27 !important;
}

.pt-ref-notes .medical-certificate .accordion-button {
  color: #44BC19 !important;
}
.react-tel-input .form-control{
  width: 100% !important;
  border: 1px solid #D9D9D9 !important;
  height: 50px !important;
}
.verify-btn .btn-primary{
  padding: 7px !important;
  border: none !important;
  right: 0;
  top: 0;
  height: 2.9rem;
}
.form-check-input:checked {
  background-color: #000071 !important;
  border: none;
}

.questionnaires-option [placeholder^="Add option"]:focus, .questionnaires-option [placeholder^="Add option"]:hover{
  border-bottom: 1px solid #CACACA !important;
  border-radius: 0px;
  box-shadow: none;
}
.questionnaires-option .form-check-label{
  width: 100%;
}
.patient__prescription-forms{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 1.5rem;
}
.patient__prescription-cards{
  background-color: #eeebff;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  height: 6rem;
  width: auto;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  text-align: center;
}

.accepted-form {
  color: #44BC19;
}

.rejected-form {
color: #FF3A3A
}

.patient__prescription-cards:hover {
  background-color: #000071;
  color: #fff;

}
.doctor-prescription-dashbaord{
  display: grid;
  gap: 1rem;
}
.doctor-prescription-tabs{
  display: grid;
  gap: 0.6rem;
}
@media (max-width: 623px) {
  .doctor-prescription-tabs {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr); }
}
@media (min-width: 600px) {
  .doctor-prescription-dashbaord { grid-template-columns: repeat(4, 1fr); }
}

.ds-top{
  position: absolute;
    margin: auto;
    right: 0;
    top: 0;
    left: 0;
    height: 13.5rem;
    background-color: #000071;
    -webkit-clip-path: ellipse(68% 40% at 49% 27%);
    clip-path: ellipse(68% 40% at 49% 27%);
}
.avatar-holder {
  position: absolute;
    margin: auto;
    top: 40px;
    right: 0;
    left: 0;
    width: 9.2rem;
    height: 9.2rem;
    border-radius: 50%;
    background: white;
    border: 3px solid #fff;
    overflow: hidden;
}
.avatar-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name{
  position: absolute;
    margin: auto;
    top: 14rem;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    font-size: 1.5rem;
}

.patient-personal-details{
  margin-top: 17rem;
}

.doctor-medication-modal .modal-dialog {
  width: 100%;
}
.policies-text{
  margin: 20px 0px 0px 0px;
  color: #74ACFF;
  font-weight: 500;
}
.policies{
  text-align: justify;
}

.img-obj-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.color-dk-blue{
  filter: brightness(0) saturate(100%) invert(6%) sepia(79%) saturate(7492%) hue-rotate(231deg) brightness(84%) contrast(138%);
}
.nav-btn-active {
  background-color: #f26522 !important;
  border: none !important;
}
.nav-btn{
  color: #B3B3B3 !important;
  background-color: #fff !important;
  border: none !important;
}

.card-content > hr:last-child {
  display: none;
}
.input-field-below-text {
  color: #9e9e9e;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 10px;
}

.custom-plans .container, .my-plans .container {
  width: 460px;
  padding: 40px;
  background: #ffffff;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  position: relative;
}
.patient__subscription-plan{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  gap: .5rem;
}

.my-plans .container {
  width: 100% !important;
}
.custom-plans .container .price {
  color: #F26522;
  font-weight: 700;
  font-size: 2.2rem;
  margin: 15px 0;
}
.custom-plans .container span {
  font-size: 1.2rem;
}
.custom-plans .container .description {
  margin: 20px 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.custom-plans .container .offer {
  display: block;
  color: #555;
  font-size: 1rem;
  margin-top: 25px;
}
.custom-plans .subscribe-button {
  display: inline-block;
  padding: 5px 0px !important;
  background: none;
  border: 2px solid #F26522;
  color: #F26522;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.2rem;
  margin-top: 40px;
  width: 40%;
  font-weight: 500;
  transition: 0.2s ease;
}
.custom-plans .subscribe-button:hover, .custom-plans .subscribe-button:active {
  background: #F26522 !important;
  border: 2px solid #F26522 !important;
  color: #fff !important;
}
.custom-plans .ribbon-wrap, .my-plans .ribbon-wrap {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  pointer-events: none;
}
.custom-plans .ribbon, .my-plans .ribbon{
  width: 150px;
  font-size: 0.918rem;
  text-align: center;
  padding: 8px 0;
  position: absolute;
  right: 0px;
  top: 6%;
}
.ribbon-wrap .active-status {
  color: #44BC19;
  background-color: #ECFFCC;
  border-radius: 0;
}
.ribbon-wrap .inactive-status {
  color: #1A1A1A;
  background-color: #F2F2F2;
  border-radius: 0;
}
.day-left-status{
  color: #FF0000;
    background-color: rgb(255, 0, 0, 10%);
    border-radius: 70px;
}
.plans-count {
  background-color: #eeebff;
  border-radius: 5px;
  padding: 10px 34px;
}
.active>.page-link{
  z-index: 0 !important;
}
.pagination__page-Number li {
  padding: 0px 4px;
}

.notification__badge .badge{
  position: absolute !important;
  top: -1.5rem !important;
  left: -0.95rem !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  border-radius: 50% !important;
  border: 1px solid #FFFFFF !important;
}
.notification__badge-text{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}