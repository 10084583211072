.Ai-Calender {
  position: absolute !important;
  top: 16px !important;
  right: 55% !important;
  color: #999999;
}

.Profile_Main_Class .Password-Sizing {
  font-size: 18px;
}
.Profile-edit .Phone-Number-FieldWidth {
  width: 90%;
  height: 45%;
}
.change-button {
  top: 90px;
  z-index: 9999;
  position: relative;
  left: 65px;
  padding: 10px 40px 10px 40px !important;
  font-size: 15px !important;
  background-color: #000071 !important;
}
.Staff-pagination react-bootstrap-table-pagination-total {
  margin-top: 20px !important;
}
.remove-button {
  margin-left: 10px;
  top: 90px;
  z-index: 9999;
  position: relative;
  left: 65px;
  padding: 10px 40px 10px 40px !important;
  font-size: 15px !important;
  background-color: #f4f4f4 !important;
  color: #999999 !important;
}

.Select-Dropdown-Sizing {
  font-size: 17px !important;
}

.Save-Changes-Btn {
  background: #000071;
  margin-right: 35px;
  padding: 12px 60px 12px 60px;
  border: none;
  color: white;
  border-radius: 5px;
}

.doctor-datatable .selection-cell-header {
  background-color: #f1f1f1 !important;
  width: 50px;
}
.Heading_Staff {
  color: #1a1a1a;
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
}
.personal-information {
  font-size: 20px;
  color: #000071;
}
.card-height {
  height: 775px !important;
}
.horizontal-line {
  width: 85%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.Change_Password {
  color: #999999 !important;
}
.Profile-edit ::-webkit-input-placeholder {
  color: #1a1a1a !important;
  font-size: 15 !important;
}

.Card-Image {
  background: #000071;
  height: 9rem;
  position: absolute;
  border-radius: 13px 13px 0px 0px;
}
.Profile-1 {
  top: 65px;
  position: relative;
  left: 35px;
}
.Personal-Information {
  margin: 29px;
  padding-left: 12px;
  font-size: 24px !important;
  border-left: 5px solid #000071;
  margin-top: 6.5rem !important;
  height: 25px;
}
.Name-Label {
  font-size: 18px;
  color: #9c9c9c;
}
.Users-List {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}
.Edit-Button {
  top: 13%;
  position: absolute;
  right: 15px;
  z-index: 99999;
  background-color: #ffffff !important;
  font-weight: 600;
  font-size: 16px;
  color: #000071 !important;
}
.personal-info-tabs {
  color: #999999 !important;
  background-color: white !important;
}
.personal-info-tabs:active {
  color: #000071 !important;
}
.personal-info-tabs:focus {
  color: #000071 !important;
}
.Personal-Info {
  font-size: 19px;
  font-weight: 500;
}
.Password-text {
  font-weight: normal;
  font-size: 18px;
}
.Password-Eye-Icon {
  right: 15px;
  top: 50px;
  position: absolute;
  cursor: pointer;
}
.Save-password-button {
  background: #000071 !important;
}
.Staff-SarchBar-Icon {
  top: 20px !important;
}
.Card-Body-Height {
  height: 720px !important;
}
.Chane-PassWord {
  padding-left: 10px;
}
.Input-Height {
  height: 50px;
  width: 90% !important;
}
.Address-Input-Height {
  height: 50px;
  width: 97% !important;
}




 /* Custom phone input field */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
  border: none;
  position: relative;
  left: 10px;
  background: none;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
 
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);

  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}
.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-left: 15px;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.Staff-Chat-MainClass .sendIcon {
  background-color: #000071 !important;
  position: absolute;
  top: 26px;
  right: 15px;
}

.Profile_Main_Class .Password-Input-Spacing {
  margin-left: 35px;
}
.Password-Input-Spacing-Second {
  margin-left: 35px !important;
}
.Profile_Main_Class .Field-Sizing {
  height: 50px !important;
}
.Field-Sizing-Second {
  height: 50px !important;
}
.Form-labeling {
  font-size: 18px;
}
.staff_main .react-bootstrap-table-pagination {
  margin-top: 20px;
}
.Payment-Details-MainClass .download-button-class {
  color: white;
  background-color: #000071;
  border: none;
  padding: 3px;
}
.Profile-popup-positioning {
  position: relative;
  left: 70px;
  font-size: 18px;
}
.Popup-date {
  position: relative;
  left: 70px;
  font-size: 14px;
}

.Invoice-Number {
  font-size: 14px;
}

.labeling-Fontsize {
  font-size: 12px !important;
}
.Address-FontSize {
  font-size: 13px;
}
.Description-Bar {
  font-size: 14px;
}
.Total-Divider {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
}
.Bill-To-Class {
  display: flex;
  justify-content: flex-end;
}

.Header-button-Height {
  height: 50px;
}
.Prescription-Main-Class .SearchBar {
  top: 15px !important;
}
.mainDiv .Additional_Main_PrescriptionTabs {
  justify-content: flex-end;
}

.Modal-MainClass .Patient-Name-FontSize {
  font-size: 30px;
}
.Modal-MainClass .table {
  margin-top: 0px;
}
.Modal-MainClass .table thead {
  border-top: 1px solid #cfc8c8;
}
.Modal-MainClass .table thead tr th {
  font-size: 18px;
  font-weight: 500;
}
.Modal-MainClass .table tbody tr {
  height: 90px;
  border-top: 1px solid rgb(201, 199, 199);
}

.Modal-MainClass .table tbody tr {
  background-color: white !important;
}
.doctor-patient-paymentdetails .Payment-SarchBar-Icon {
  top: 40px !important;
}
.doctor-patient-paymentdetails .patient-details-card .table-header-border {
  border: none !important;
}
.doctor-patient-paymentdetails .patient-details-card .react-bootstrap-table {
  border: none !important;
}
.Modal-MainClass .modal-footer {
  border: none !important;
}
.signup-card-mainclass .button-spacing {
  margin-top: 85px;
  background: #000071;
  border: none;
  letter-spacing: 0.7px;
  font-size: 18px;
}
.Staff-Chat-MainClass .padding {
  padding: 22px !important;
}
.Prescription-SarchBar-Icon {
  top: 15px !important;
}
.modal-width {
  width: 700px !important;
}

.modal-main .modal-content {
  width: 700px !important;
}

.consultation-main .Tabs-Bordered {
  border-bottom: none !important;
}

.consultation-main .nav-tabs .nav-item .active,
.nav-tabs .nav-item :focus {
  border-top: 5px solid #000071 !important;
  border-bottom: none !important;
}

.doctor-patient-paymentdetails .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #f26522 !important;
  border-color: transparent !important;
  color: white !important;
  padding: 14px 20px;
}

.usermanagement-mainclass .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #f26522 !important;
  border-color: transparent !important;
  color: white !important;
  padding: 14px 20px;
}

.Add-Admin {
  color: white;
  background-color: #000071;
  border: none;
  padding: 13px 30px;
  font-size: 17px;
  letter-spacing: 1px;
}
.doctor-patient-paymentdetails .Admin-Tabs-All {
  padding: 13px 30px !important;
}

.usermanagement-mainclass .Admin-Tabs-All {
  padding: 13px 30px !important;
}

.Admin-Modal-CancelBtn {
  padding: 13px 35px !important;
  color: #1a1a1a !important;
  background: #cccccc !important;
  border: none !important;
  font-size: 18px;
}
.Admin-Add-btn {
  padding: 13px 35px !important;
  background: #000071 !important;
  border: none !important;
  font-size: 18px;
}

.Setting-Main-Class .Personal-Information {
  border-left: none !important;
}
.Setting-Main-Class .setting-save-button {
  padding-bottom: 90px;
}
.Setting-Main-Class .account-setting {
  font-size: 30px;
  font-weight: 700;
}
.Setting-Main-Class .changepassword-size {
  font-size: 22px;
}
.Setting-Main-Class .Password-text {
  margin: 20px 80px;
}
.doctor-patient-paymentdetails
  .doctor-datatable
  .react-bootstrap-table-pagination,
.patient-datatable .react-bootstrap-table-pagination {
  padding: 10px 0px 15px 0px !important;
}

.doctor-patient-paymentdetails .selection-cell {
  height: 60px !important;
}
.Prescription-Main-Class .react-bootstrap-table table tbody tr td {
  height: 60px !important;
}
.Prescription-Main-Class .react-bootstrap-table-pagination {
  padding: 15px 20px 15px 20px !important;
}
.patient-profile .Card-Image {
  height: 10rem;
  border-radius: 10px 10px 0px 0px;
}
.patient-profile .Profile-1 {
  top: 50px;
  position: relative;
  left: 38px;
}
.patient-profile .Edit-Button {
  padding: 10px 20px;
  /* top: 15%; */
  position: absolute;
  right: 30px;
  z-index: 99999;
  background-color: #ffffff !important;
  font-weight: 600;
  font-size: 16px;
  color: #000000 !important;
}
.patient-profile .Personal-Information {
  font-size: 18px !important;
}
.patient-profile .Name-Label {
  margin-top: 30px;
}
.patient-profile .Users-List {
  font-size: 18px;
}
.Profile_Main_Class .nav-pills .nav-link.active .Arrow-ForwardIcon,
.nav-pills .show > .nav-link .Arrow-ForwardIcon {
  display: block !important;
}

.Profile_Main_Class .nav-pills .nav-link .Arrow-ForwardIcon {
  display: none !important;
}

.patient-profile .profile-header-name {
  position: absolute;
  top: 15%;
  left: 19%;
  color: #ffffff;
}
.patient-profile .profile-header-gender {
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;

  left: 229px !important;
}
.Testprofile {
  z-index: 9999999 !important;
  position: relative;
  top: 40px;
  left: 30px;
}
.patient-profile .profile-header {
  background: transparent;
  position: relative;
  border: none;
  color: white;
  padding: 0rem 0rem 0rem 2.3rem !important;
  font-size: 24px;
  font-weight: 600;
}

.doctor-patient-paymentdetails .table-normal-text {
  width: 60%;
  text-align: start;
}
.usermanagement-mainclass .Payment-SarchBar-Icon {
  top: 16px !important;
}
.usermanagement-mainclass .custom-width-class {
  width: 70%;
}

.patient-profile .Profile-2 {
  left: 30px !important;
  top: 80px !important;
}

.patient-profile .Profile-2 {
  top: 80px !important;
  left: 30px !important;
  height: 165px;
  width: 165px;
  object-fit: cover;
  border-radius: 50%;
}
.main-personalprofile .Profile-2 {
  top: 80px !important;
  left: 30px !important;
  height: 165px;
  width: 165px;
  object-fit: cover;
  border-radius: 50%;
}
.generic-card .main-personalprofile .Profile-2 {
  top: 35px !important;
  left: 5px !important;
  height: 165px;
  width: 165px;
  object-fit: cover;
  border-radius: 50%;
}
.test-border{
  border: 5px solid #FFFFFE !important;
    border-width: thick;
    background-color: white;
  }
  



/* MEDIA QUERIES CODE HERE */

@media screen and (max-width: 768px) {
  .patient-profile .profile-header-gender {
    font-size: 15px !important;
  }
  .patient-profile .Edit-Button {
    padding: 2px !important;
  }
  .patient-profile .profile-header {
    font-size: 16px !important;
  }
  .setting_submain {
    width: 100% !important;
  }
  .Address-Input-Height {
    width: 90% !important;
  }
  .SearchBar {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .Password-text {
    font-size: 15px;
  }

  .custom-dropdown {
    margin-right: 2px !important;
  }
  .Edit-Button {
    top: 10%;
  }
  .Save-Changes-Btn {
    padding: 12px 40px 12px 40px !important;
  }
}

@media screen and (max-width: 1248px) {
  .personal-information,
  .Change_Password {
    padding-left: 30px !important;
  }
}
@media screen and (max-width: 1200px) {
  .Name-Label {
    margin-top: 20px;
  }
  .Personal-Information {
    margin-top: 20% !important;
  }

  .Profile-1 {
    height: 160px !important;
  }
}

@media screen and (max-width: 1100px) {
  .patient-profile .Edit-Button {
    padding: 3px 10px !important;
  }

  .setting_submain {
    width: 100% !important;
  }
  .patient-profile .Edit-Button {
    top: 2% !important;
    position: absolute;
  }
}

@media screen and (max-width: 992px) {
  .Bill-To-Class {
    display: flex;
    justify-content: flex-start;
  }
  .Profile_Main_Class .Arrow-ForwardIcon {
    position: relative;
    left: 20px;
    top: 4px;
  }
  .card-height {
    height: 200px !important;
  }
  .Change_Password {
    justify-content: center;
  }
  .Personal-Info {
    display: flex;
    justify-content: center;
  }

  .Address-Input-Height {
    width: 95% !important;
  }
  .Password-Sizing {
    margin-right: 65px !important;
  }
}
@media screen and (max-width: 576px) {
  .patient-profile .Card-Image {
    height: 8rem !important;
  }
  .patient-profile .Profile-1 {
    height: 100px !important;
    top: 80px;
  }

  .patient-profile .Edit-Button {
    padding: 2px !important;
  }
  .Ai-Calender {
    color: #999999;
    top: 98px;
    position: absolute;
    right: 98px;
  }
  .remove-button {
    margin-top: 45px;
    padding: 10px 20px 10px 20px !important;
  }
  .change-button {
    margin-top: 40px;
    padding: 10px 20px 10px 20px !important;
    left: 40px;
  }
  .Save-Changes-Btn {
    padding: 12px 20px 12px 20px !important;
  }
  .Profile-1 {
    /* height: 130px!important; */
    top: 80px;
  }
  .Name-Label {
    font-size: 15px;
  }
  .Users-List {
    font-size: 17px;
  }
  .Personal-Information {
    margin-top: 30% !important;
  }
  .card-height {
    height: 200px !important;
  }
  .Confirm-Password {
    font-size: 15px;
  }
}

@media screen and (max-width: 475px) {
  .change-button,
  .remove-button {
    padding: 5px 5px 5px 5px !important;
  }
}
@media screen and (max-width: 425px) {
  .Ai-Calender {
    top: 18%;
  }
  .remove-button {
    margin-left: 0px;
  }
  .Profile-1 {
    /* height: 100px!important; */
    left: 20px;
  }
  .Personal-Information {
    margin-top: 45% !important;
  }
  .Edit-Button {
    top: 2%;
  }
  .Personal-Information {
    font-size: 16px !important;
  }
  .Password-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  .Setting-Main-Class .setting-save-button {
    display: block;
    justify-content: center;
    display: flex;
  }
  .Setting-Main-Class .eye-icon {
    position: absolute;
    right: 35px;
  }
  .Setting-Main-Class .Form-labeling {
    display: flex;
    justify-content: center;
  }
  .Setting-Main-Class .Field-Sizing {
    width: 80%;
  }
  .Prescription_SpecificResponsive {
    margin-left: 20px !important;
    margin-top: 25px !important;
  }
  .change-button {
    left: 20px;
    font-size: 12px !important;
  }
  .remove-button {
    left: 25px;
    font-size: 12px !important ;
  }
  .Change_Password {
    padding-left: 0px !important;
  }
}
